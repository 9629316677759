import React, { useState } from 'react';
import OrderForm from "../components/OrderForm";
import Footer from "../components/Footer";
import '../styles/Order.css';

const Order = () => {
	const TAX_RATE = 0.09;
	const FOUR_STICK_ALBI_PRICE = 20.0;
	const SIX_STICK_ALBI_PRICE = 30.0;

	const products = [
		{
			name: "latinNouns",
			title: "Latin Noun Albi",
			description: "Four double-sided sticks to help you master Latin noun endings.",
			price: FOUR_STICK_ALBI_PRICE,
			thumbnail: "/LatinNouns",
			alt: "Latin Noun Albi thumbnail",
			inStock: true
		},
		{
			name: "latinVerbs",
			title: "Latin Verb Albi",
			description: "Four double-sided sticks to help you master Latin present-stem verb endings.",
			price: FOUR_STICK_ALBI_PRICE,
			thumbnail: "/LatinVerbs",
			alt: "Latin Verb Albi thumbnail",
			inStock: true
		},
		{
			name: "greekNouns",
			title: "Greek Noun Albi",
			description: "Four double-sided sticks to help you get started with Greek nouns.",
			note: "Coming Soon",
			price: FOUR_STICK_ALBI_PRICE,
			thumbnail: "/coin",
			alt: "Greek Noun Albi thumbnail",
			inStock: false
		},
		{
			name: "greekVerbs",
			title: "Greek Verb Albi",
			description: "Six double-sided sticks to help you get started with Greek verbs.",
			price: SIX_STICK_ALBI_PRICE,
			thumbnail: "/GreekVerbs",
			alt: "Greek Verb Albi thumbnail",
			inStock: true
		}
	];

	const availableProducts = products.filter(x => x.inStock);

	const names = availableProducts.map(product => product.name);
	const prices = availableProducts.map(product => product.price);
	const quantities = availableProducts.map(product => 0);
	const [orderFormData, setOrderFormData] = useState({
		taxRate: TAX_RATE,
		productNames: names,
		productPrices: prices,
		productQuantities: quantities,
	});

	const [status, setStatus] = useState("Make your product selections and enter your " +
		"shipping information above");

	const resetQuantities = () => {
		setOrderFormData({
			...orderFormData,
			productQuantities: quantities
		});
	};

	const handleSelectChange = e => {
		const { value, type, dataset } = e.target;
		if (type !== "select-one")
			return;
		
		const quantities = orderFormData.productQuantities.slice();
		quantities[dataset.index] = value;
		const newQuantities = quantities;

		setOrderFormData({
			...orderFormData,
			productQuantities: newQuantities
		});

		setStatus("Click Submit Order when you're ready to check out...");
	};

	const calculateTotal = () => {
		let subtotal = 0.0;
		for (let i = 0; i < orderFormData.productPrices.length; i++)
			subtotal += orderFormData.productPrices[i] * orderFormData.productQuantities[i];

		const tax = Math.round(subtotal * orderFormData.taxRate * 100) / 100;
		const total = subtotal + tax;
		return {
			subtotal,
			tax,
			total
		};
	};

	const { subtotal, tax, total } = calculateTotal();

	return (
		<div className="order-container">
			<h1 className="order-title">
				Albi<span className="trademark">™</span> Handheld Adapters
				<span className="trademark">™</span> Order Page
			</h1>
			<p className="order-subtitle">Please note that this page is under construction.
				While we complete the website, please send your inquiries to albistix@outlook.com
				from the Home page. Thank you for your patience!</p>
			<div>
				<ul className="order-list">
					{products.map((product, index) => (
						<li key={index} className="order-item">
							<div className="order-content">
								<div className="order-product-text">
									<h2>{`${product.title} $${product.price.toFixed(2)}`}</h2>
									<p>{product.description}</p>
									<p><em><strong>{product.note}</strong></em></p>
								</div>
								<div className="order-product-thumbnail">
									<img alt={`${product.title} thumbnail`}
										src={`${product.thumbnail}-125.jpg`}
										srcSet={`${product.thumbnail}-125.jpg 125w,
											${product.thumbnail}-250.jpg 250w,
											${product.thumbnail}-375.jpg 375w`}
										sizes="125px" />
							</div>
							</div>
						</li>
					))}
				</ul>

				<div className="quantity-cost-section">
					<ul className="quantity-section">
						<h3>Order List</h3>
						{availableProducts.map((product, index) => (
							<li key={index} className="quantity-item">
								<select
										name="productQuantities"
										value={orderFormData.productQuantities[index]}
										data-index={index}
										onChange={handleSelectChange}>
									{[...Array(11)].map((i, count) => (
										<option key={count} value={count}>
											{count}
										</option>
									))}
								</select>
								<label>{`${product.title}, $${product.price}`}</label>
							</li>
						))}
					</ul>

					<div name="summary" className="cost-summary-card">
						<h3>Order Summary</h3>
						<div className='summary-line'>
							<p className='Label'>Subtotal:</p>
							<p className='Value'>${subtotal.toFixed(2)}</p>
						</div>
						<div className='summary-line'>
							<p className='Label'>Tax ({orderFormData.taxRate * 100}%):</p>
							<p className='Value'>${tax.toFixed(2)}</p>
						</div>
						<div className='summary-line'>
							<p className='Label'><strong>Total:</strong></p>
							<p className='Value'><strong>${total.toFixed(2)}</strong></p>
						</div>
					</div>
				</div>

				<OrderForm products={products} orderFormData={orderFormData}
					subtotal={subtotal} tax={tax} total={total}
					resetQuantities={resetQuantities} setStatus={setStatus} />
			</div>
			<p className="order-status">{status}</p>
			<Footer />
		</div>
	);
};

export default Order;
