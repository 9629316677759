import React, { useRef, useEffect } from "react";
import { ValidationError, useForm } from '@formspree/react';
import '../styles/OrderForm.css';

const OrderForm = ({ products, orderFormData, subtotal, tax, total, resetQuantities, setStatus }) => {
	const formRef = useRef(null);
	let ordersList = [];
	for (let i = 0; i < orderFormData.productNames.length; i++) {
		ordersList.push(`${orderFormData.productNames[i]}: ${orderFormData.productQuantities[i]}` +
			` @ $${orderFormData.productPrices[i].toFixed(2)}`);
	}

	let notInStockList = [];
	products.filter(product => !product.inStock).forEach(product =>
		notInStockList.push(`${product.name}, price $${product.price}`));
		
	const orders = ordersList.join('\n');
	const notInStock = notInStockList.join('\n');
	const [state, handleFormSubmit, reset] = useForm('xrbzddjy', {
		data: {
			subtotal: `$${subtotal.toFixed(2)}`,
			tax: `$${tax.toFixed(2)}`,
			total: `$${total.toFixed(2)}`,
			orders: orders,
			notInStock: notInStock
		}
	});

	useEffect(() => {
		if (state.submitting) {
			setStatus("Submitting...");
		}
		else if (state.succeeded) {
			setStatus("Thanks for your order! We will get back to you soon.");
			reset();
			resetQuantities();
			if (formRef.current)
				formRef.current.reset();
		}
		else if (state.errors !== null) {
			setStatus(`Error: ${state.errors}`);
		}
	}, [state, setStatus, reset, resetQuantities]);
	
	const handleSubmit = async e => {
		e.preventDefault();
		if (total === 0) {
			alert('Please select at least one product to order.');
			return;
		}

		handleFormSubmit(e);
	}

	return (
		<form className="order" ref={formRef} onSubmit={handleSubmit}>
			<input placeholder="Full Name" name="name" required />
			<ValidationError field="name" prefix="Name" errors={state.errors} />
			<input placeholder="Shipping Address" name="address" required />
			<ValidationError field="city" prefix="City" errors={state.errors} />
			<input placeholder="City" name="city" required />
			<ValidationError field="state" prefix="State" errors={state.errors} />
			<input placeholder="State" name="state" required />
			<ValidationError field="state" prefix="State" errors={state.errors} />
			<input placeholder="ZIP" name="zip" required />
			<ValidationError field="zip" prefix="ZIP" errors={state.errors} />
			<input placeholder="Email Address" type="email" name="Email" required />
			<ValidationError field="email" prefix="Email" errors={state.errors} />
			<textarea placeholder="Message" name="message" />
			<button type="submit" disabled={state.submitting}>Submit Order</button>
		</form>
	)
};

export default OrderForm;
