import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Carousel from "../components/Carousel";
import MessageForm from "../components/MessageForm";
import Footer from "../components/Footer";
import "../styles/Home.css";

const Home = () => {
	const phrases = useMemo(() => [
		{ latin: "Carpe diem", meaning: "Seize the day" },
		{ latin: "Deo optimo maximo", meaning: "To God the best" },
		{ latin: "Veni, vidi, vici", meaning: "I came, I saw, I conquered" },
		{ latin: "Deo volente", meaning: "God willing" },
		{ latin: "Alea iacta est", meaning: "The die is cast" },
		{ latin: "Ad astra per aspera", meaning: "Through hardships to the stars" },
		{ latin: "Tempus fugit", meaning: "Time flies" },
		{ latin: "Deus Misereatur", meaning: "May God have mercy" },
		{ latin: "Amor vincit omnia", meaning: "Love conquers all" },
		{ latin: "Fortuna favet fortibus", meaning: "Fortune favors the brave" },
		{ latin: "Homo homini lupus", meaning: "Man is a wolf to man" },
		{ latin: "Omnia vincit labor", meaning: "Work conquers all" },
		{ latin: "Deo iuvante", meaning: "With God's help" },
		{ latin: "Acta non verba", meaning: "Actions, not words" },
		{ latin: "Dum spiro, spero", meaning: "While I breathe, I hope" },
		{ latin: "Nullum magnum ingenium sine mixtura dementiae",
			meaning: "There is no great genius without a touch of madness", },
		{ latin: "Memento mori", meaning: "Remember that you will die" },
		{ latin: "Quid pro quo", meaning: "Something for something" },
		{ latin: "Deo gratis", meaning: "Thanks to God" },
		{ latin: "Diem perdidi", meaning: "Another day wasted" },
		{ latin: "In vino veritas", meaning: "In wine, the truth" },
		{ latin: "Qui me amat, amat et canem meam", meaning: "Who loves me also loves my dog" },
		{ latin: "Amor Vincit Omnia", meaning: "Love conquers all" },
		{ latin: "de nihilo nihil", meaning: "Nothing comes from nothing" },
		{ latin: "Deo Favente", meaning: "With God's favor" },
		{ latin: "Deus vult", meaning: "God wills it" },
		{ latin: "Dies faustus", meaning: "An auspicious day" },
		{ latin: "Dii penates", meaning: "Guardians of the household" },
		{ latin: "dis aliter visum", meaning: "Man proposes, God disposes" },
		{ latin: "Deus vobiscum", meaning: "God be with you" },
		{ latin: "Docendo discimus", meaning: "We learn by teaching" },
		{ latin: "Id faciamus", meaning: "Let's do it" }
	], []);

	const reasons = useMemo(() => [
		{ reason: "Connect with history", detail: "Discover the mechanics of " +
			"ancient languages of Europe." },
		{ reason: "Strengthen logic", detail: "Learning Latin's structured " +
			"grammar helps develop logical thinking and problem-solving skills." },
		{ reason: "Deepen your understanding of English", detail: "Give your " +
			"vocabulary, spelling, and insight a permanent boost." },
		{ reason: "Learn European languages more easily", detail: "Most European " +
			"languages share Latin as a source." }
	], []);

	const testimonials = useMemo(() => [
		{ name: "Cutzi, happy mom", testimonial: "You're always so encouraging Jean! " +
			"I appreciate you so much... you [and your Albi Stix] are single-handedly " +
			"saving Latin students in Whatcom County. Seriously!" },
		{ name: "Cutzi, happy mom", testimonial: "I thought Xochi might want " +
			"a set after she saw them." },
		{ name: "Tobin and Xochi", testimonial: "They're really cool!" },
		{ name: "Peter, Student", testimonial: "Albi Stix have placed me in " +
			"a prestigious university!" },
		{ name: "Jamey, Talented Aunt", testimonial: "When I wake up, I first " +
			"reach for my Albi" }
	], []);

	const [currentPhrase, setCurrentPhrase] = useState({});
	const [showMeaning, setShowMeaning] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [wotdIndex, setWotdIndex] = useState(-1);

	useEffect(() => {
		// zero-based day of year, month is zero-based, day is one-based
		const now = new Date();
		const dayOfYear = Math.floor((now - new Date(now.getFullYear(), 0, 1)) / 1000 / 60 / 60 / 24);
		const phraseNumber = wotdIndex > -1 ? wotdIndex % phrases.length : dayOfYear % phrases.length;
		const phrase = phrases[phraseNumber];
		setWotdIndex(phraseNumber);
		setCurrentPhrase(phrase);
	}, [phrases, wotdIndex]);

	// after three click cycles with today's phrase, advance to the next phrase
	// with each click, show Latin, English, then Latin again, then advance the phrase index
	// ignoring missing default case eslint warning
	const handleWotdClick = () => {
		if (clickCount > 5) {
			// eslint-disable-next-line
			switch (clickCount % 3) {
				case 0:
					setWotdIndex(wotdIndex + 1);
					break;
				case 1:
				case 2:
					setShowMeaning(current => !current);
					break;
			}
		} else {
			setShowMeaning(current => !current);
		}

		setClickCount(clickCount + 1);
	};

	return (
		<div className="home-container">
			<header className="hero-section">
				<h1 className="hero-title">Adapt yourself to Latin with an Albi</h1>
				<p className="hero-subtitle">
					Explore the power of Latin endings with this interactive new tool,
					designed for Latin students of all ages. <br />
				</p>
				<strong className="hero-subtitle">Here's to the right ending!</strong>
				<Link to="/products" className="hero-nav-link">
					<button className="hero-button" Link to="/products">
						Start Your Journey
					</button>
				</Link>
			</header>

			<section className="about-latin">
				<section className="about-latin-section">
					<div className="about-card about-card-cursor-pointer" onClick={handleWotdClick}>
						<h2>Latin Phrase of the Day</h2>
						<div className="phrase-container">
							<p className={`phrase-latin fade ${showMeaning ? 'fade-in' : ''}`}>{currentPhrase.meaning}</p>
							<p className={`phrase-meaning fade ${showMeaning ? '' : 'fade-in'}`}>{currentPhrase.latin}</p>
						</div>
						<p className="phrase-instruction">
							(Click the card to reveal the meaning!)
						</p>
					</div>
				</section>

				<section className="about-latin-section">
					<div className="about-card">
						<h2>Why learn Latin?</h2>
						<div className="about-latin-content">
							<div className="latin-benefits">
								{reasons.map((reason, index) => (
									<p>
										<strong>{reason.reason}:</strong> {reason.detail}
									</p>
								))}
							</div>
							<div className="latin-image">
								<img
									src="https://th.bing.com/th/id/R.ccb66930a87e341d40adfdfccd51ef9d?rik=AniCcI6aFHCW3A&pid=ImgRaw&r=0"
									alt="Latin Learning" />
							</div>
						</div>
					</div>
				</section>

				<section className="about-latin-section">
					<div className="testimonial-container">
						<h2>What People are Saying</h2>
						<Carousel testimonials={testimonials} />
					</div>
				</section>
			</section>

			<section className="message-section">
			<MessageForm />
			</section>
			<Footer />
		</div>
	);
};

export default Home;
