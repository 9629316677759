import React, { useRef, useState, useEffect } from 'react';
import '../styles/Carousel.css';

const Carousel = ({ testimonials }) => {
	const carouselRef = useRef(null);
	const [hasFocus, setHasFocus] = useState(false);

	useEffect(() => {
		document.body.style.overflow = hasFocus ? 'hidden' : 'auto';
	}, [hasFocus]);

	useEffect(() => {
		const handleResize = () => {
			const containerWidth = 
				Math.min(carouselRef.current.getBoundingClientRect().width - 20, 300);

			document.documentElement.style.setProperty('--cell-width', `${containerWidth}px`)
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);	

	const handleScroll = e => {
		e.preventDefault();
		const scrollAmount = e.deltaY < 0 ? -200 : 200;
		carouselRef.current.scrollBy({
			left: scrollAmount,
			behavior: 'smooth'
		});
	};

	return (
		<div ref={carouselRef}
				className="carousel-container"
				onWheel={handleScroll}
				onMouseEnter={() => setHasFocus(true)}
				onMouseLeave={() => setHasFocus(false)}>
			<div className="carousel">
				{testimonials.map((testimonial, index) => (
					<div className='cell'>
						<blockquote>
							{testimonial.testimonial}
							<span>{`- ${testimonial.name}`}</span>
						</blockquote>
					</div>
				))}
			</div>
		</div>
	)
};

export default Carousel;
