import React, { useState, useRef, useEffect } from "react";
import { ValidationError, useForm } from '@formspree/react';
import '../styles/MessageForm.css';

const MessageForm = () => {
	const [state, handleSubmit, reset] = useForm('xrbgjdke');
	const [status, setStatus] = useState("");
	const formRef = useRef(null);

	useEffect(() => {
		if (state.submitting) {
			setStatus("Submitting...");
		}
		else if (state.succeeded) {
			setStatus("Thanks for your inquiry! We will get back to you soon.");
			reset();
			if (formRef.current)
				formRef.current.reset();
		}
		else if (state.errors !== null) {
			setStatus(`Error: ${state.errors}`);
		}
	}, [state, reset]);

	return (
		<div>
			<h2>Contact Us</h2>
			<h4>
				We would love to hear from you! If you have a question about an Albi
				please use this form.
			</h4>
			<form className="message-form" ref={formRef} onSubmit={handleSubmit}>
				<input id="name" name="name" placeholder="Full Name" required />
				<ValidationError field="name" prefix="Name" errors={state.errors} />
				<input id="email" name="email" type="email" placeholder="Email Address" required />
				<ValidationError field="email" prefix="Email" errors={state.errors} />
				<textarea id="message" name="message" placeholder="Message" required />
				<ValidationError field="message" prefix="Message" errors={state.errors} />
				<button type="submit" disabled={state.submitting}>Send Message</button>
			</form>
			<p>{status}</p>
		</div>
	);
};

export default MessageForm;
